import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import Aside from "../components/aside"
import { Link } from "gatsby"
import Seo from "../components/seo"

const ArticleList = ({ data, pageContext }) => {
  const edges = data.nanoCms.articles.edges
  edges.map(edge => edge.node)
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const location = "ja"
  const { currentPage, numPages } = pageContext
  const prevPageLink = currentPage - 1 === 1 ? "/" : `/pages/${currentPage - 1}`
  const nextPageLink = `/pages/${currentPage + 1}`
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages

  return (
    <Layout location={location} title={siteTitle}>
      <main>
        <div className="image-heading">
          <StaticImage
            src="../images/main-head.png"
            width={4000}
            alt="topの画像"
          />
        </div>
        <div className="cntWrapper">
          <div className="cntWrapperIndex">
            <div className="mainnav">
              <section>
                {data.nanoCms.articles.edges.map(({ node }) => (
                  <article className="articleItem">
                    <div className="articleLeft">
                      <Link to={`/${node.url}/`}>
                        {node.ogpImage ? (
                          <img
                            src={node.ogpImage}
                            alt="ogp"
                            className="aside-content-left-picture"
                          />
                        ) : (
                          <StaticImage
                            src="../images/nanocms.png"
                            alt="topの画像"
                            className="aside-content-left-picture"
                          />
                        )}
                      </Link>
                    </div>
                    <div className="articleRight">
                      <Link to={`/${node.url}/`}>
                        <h2>{node.title}</h2>
                      </Link>
                      <p className="articleRightSubtitle">{node.description}</p>
                      <div className="articleRightDate">
                        <span>
                          <time>{node.updatedAt}</time>
                        </span>
                      </div>
                    </div>
                  </article>
                ))}
              </section>
              {/* Pagination Links */}
              <div className="pagination-box">
                {numPages > 3 && (
                  <Link
                    to="/"
                    className="btn pagination-btn-side me-1 rounded-30"
                  >
                    最初
                  </Link>
                )}
                {!isFirst && (
                  <Link
                    to={prevPageLink}
                    className="btn pagination-btn-side me-1 rounded-30"
                  >
                    前へ
                  </Link>
                )}

                {Array.from({ length: numPages }, (_, i) => i + 1)
                  .filter(pageNumber => Math.abs(pageNumber - currentPage) <= 1)
                  .map(pageNumber => (
                    <Link
                      key={`pagination-number${pageNumber}`}
                      to={pageNumber === 1 ? "/" : `/pages/${pageNumber}/`}
                      className={
                        pageNumber === currentPage
                          ? "btn pagination-btn active me-1 rounded-30"
                          : "btn pagination-btn me-1 rounded-30"
                      }
                    >
                      {pageNumber}
                    </Link>
                  ))}

                {!isLast && (
                  <Link
                    to={nextPageLink}
                    className="btn pagination-btn-side me-1 rounded-30"
                  >
                    次へ
                  </Link>
                )}
                {!isLast && numPages > 3 && (
                  <Link
                    to={`/pages/${numPages}/`}
                    className="btn pagination-btn-side me-1 rounded-30"
                  >
                    最後
                  </Link>
                )}
              </div>
            </div>
            <Aside />
          </div>
        </div>
      </main>
    </Layout>
  )
}

export const Head = ({ data }) => {
  return (
    <Seo description="東京を中心に活動している、WEBデザイン事務所 jamstackホームページ制作屋 ハイスピード、安心のアフターサポート" />
  )
}

export default ArticleList

export const query = graphql`
  query ($limit: Int, $startKey: Int) {
    nanoCms {
      articles(isPublished: true, limit: $limit, startKey: $startKey) {
        edges {
          node {
            title
            url
            description
            ogpImage
            updatedAt
            timestamp
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
